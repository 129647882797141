import axios from 'axios'

export const NahrajFileIdentifikaci = async (accessToken,id,zastupceId,fileList) => {  
  let param = ''; 
  if (process.env.NODE_ENV === 'development') {
    param = 'files';   
  } else {
    param = 'files[]';   
  }   
  const formData = new FormData();
  for (let i = 0; i < fileList.length; i++) {
    formData.append(param, fileList[i]);
  }
  
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/identifikace/upload/'+id+'/'+zastupceId;
  
  try {
      const response = await axios.post(REST_API_URL,formData, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0","Content-Type":"multipart/form-data","Authorization" : `Bearer ${accessToken}`},timeout:(process.env.REACT_APP_UPLOAD_TIMEOUT*fileList.length) });     
      //console.log(response);
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1}; 
   }
}

export const NahrajFormIdentifikaci = async (accessToken,id,zastupceId,uzivData) => {    
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/identifikace/upload/'+id+'/'+zastupceId;

  try {
      const response = await axios.post(REST_API_URL,uzivData, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0","Content-Type":"application/json","Authorization" : `Bearer ${accessToken}`},timeout:process.env.REACT_APP_TIMEOUT });
      //console.log(response);
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1}; 
   }
}

// export const VratZastupceKlienta = async (accessToken,adresarID) => {
//   console.log("VratZastupceKlienta: "+adresarID);
//   const REST_API_URL = 'http://localhost:8080/api/unileasing/adresar/'+adresarID;
//  try {
//       const response = await axios.get(REST_API_URL, { headers: { "Authorization": `Bearer ${accessToken}` } });
//       console.log(response.data);
//       return response.data;
//   } catch (error) {
//       return console.log(error);
//   } 
// }

export const VratIdentifikaci = async (accessToken,id) => {    
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/identifikace/'+id;
try {
  const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
  //console.log(response);
  return response;
 } catch (error) {
  console.log(error);
  if (error.response) return error.response
  else return {status: -1}; 
 } 
}

export const StahniSoubor = async (accessToken,id) => {    
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/identifikace/download/'+id;
try {
  //console.log('StahniSoubor');
  const response = await axios.get(REST_API_URL, { responseType:"blob", headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
  //console.log(response);
  return response;
 } catch (error) {
  console.log(error);
  if (error.response) return error.response
  else return {status: -1}; 
 } 
}

export const ZkontrolujOP = async (cisloop) => {    
  const REST_API_URL = 'https://webunikalk.unileasing.cz/proxyOP.php?doklad='+cisloop;
try {
  const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0"}});
  return response;
 } catch (error) {
  console.log(error);
  if (error.response) return error.response
  else return {status: -1}; 
 } 
}