import { useState,useRef,useEffect } from 'react';
import { loginRequest } from "../authConfig";
import {Alert,Box } from '@mui/material';
import { NahrajFileIdentifikaci } from '../services/IdentService';
import { useMsal} from "@azure/msal-react";
import { NedostupnaSluzba,NeocekavanaChyba} from "./StavoveHlasky";
import {isMobile} from 'react-device-detect';
import Fotak2 from "./Fotak2";
import { UniUploadButton } from './UniUploadButton';
import UniCircularProgressBar from "./UniCircularProgressBar";

function IdentifikaceSoubory(props) {
  const { instance,accounts } = useMsal();
  let [status,setStatus] = useState(0);
  let [error,setError] = useState();
  let [uploadingImages,setUploadingImages] = useState(false);
  const drop = useRef(null);
  

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  
    const {files} = e.dataTransfer;
  
    if (files && files.length) {
      uploadFiles(files);     
    }
  };

  const handleUploadedFiles = (e) => { 
    uploadFiles(e.target.files);
  }


  const ulozIdentifikaci=(accessToken,files) => {
    setUploadingImages(true);
    NahrajFileIdentifikaci(accessToken,props.id,props.zastupceid,files).then(files => {
      //console.log(files.data);
      setStatus(files.status);  
      if (files.data && files.status === 200) {
        props.setIdentData(props.identData.map(ident => {
          if(ident.zastupceAdresarId === files.data.zastupceAdresarId) {
            return files.data;
          } else {
            return ident;
          }
        }));
      } 
      if (files.data && files.status > 200) setError(files.data);   
    });
    setUploadingImages(false); 
  }

  const uploadFiles = (files) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    try {
    instance.acquireTokenSilent(request).then((response) => {
      ulozIdentifikaci(response.accessToken,files);
    }).catch(() => {
      instance.acquireTokenPopup(request).then((response) => {
        ulozIdentifikaci(response.accessToken,files);
      });
    });
    } catch(e) {
      console.log(e);
    }   
  }

  useEffect(() => {
    // console.log(drop);


    // if (!isMobile) {
    //   drop.current.addEventListener('dragover', handleDragOver);
    //   drop.current.addEventListener('drop', handleDrop);
    // }


    // return () => {
    //   console.log(drop);
    //   drop.current.removeEventListener('dragover', handleDragOver);
    //   drop.current.removeEventListener('drop', handleDrop);
    // };
  }, []);

  return (
    <div>
      <Box sx={{display:"flex",alignItems:"center"}}>
        {isMobile ?
          <Fotak2 typ={-1} handleUploadedFiles={uploadFiles}></Fotak2> : <></>
        }
        <UniUploadButton typ={-1} pdf={true} handleUploadedFiles={uploadFiles}/>
        {uploadingImages ?<UniCircularProgressBar /> :<></>}

      </Box>
      <div>{status === 200 ? <Alert severity="success">Identifikace byla úspěšně nahrána na server!</Alert>:<p></p>}</div>
     <div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
     <div>{status > 200  ? <NeocekavanaChyba chyba={error} />:<p></p>}</div>  
    </div>
  );
}

export default IdentifikaceSoubory;