import axios from 'axios'

export const VratUzivatele = async (accessToken) => {    
	
	const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/user/';
	//const REST_API_URL = 'https://testapiserver:46852/api/unileasing/user/';
	//console.log(REST_API_URL);
try {
	const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
	//console.log(response);
	return response;
 } catch (error) {
	console.log(error);
	if (error.response) return error.response
	else return {status: -1};    
 } 
}