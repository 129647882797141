import axios from 'axios'

export const VratPojisteni = async (accessToken,idSmlouva) => {    
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/pojisteni/'+idSmlouva;
  try {
    const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
    //console.log(response);
    return response;
   } catch (error) {
    console.log(error);
    if (error.response) return error.response
    else return {status: -1}; 
   } 
  }

  export const SjednejPojisteni = async (accessToken,idSmlouva, data) => {    
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/pojisteni/sjednat/'+idSmlouva;
  try {
    const response = await axios.post(REST_API_URL,data, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0","Content-Type":"application/json", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_SJEDNANI_TIMEOUT });
    //console.log(response);
    return response;
   } catch (error) {
    console.log(error);
    if (error.response) return error.response
    else return {status: -1}; 
   } 
  }  

  export const VratDokumenty= async (accessToken,idSmlouva) => {    
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/pojisteni/dokumenty64/'+idSmlouva;
  try {
    const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
    //console.log(response);
    return response;
   } catch (error) {
    console.log(error);
    if (error.response) return error.response
    else return {status: -1}; 
   } 
  }  

  export const VratZelenouKartuBase64 = async (accessToken,idSmlouva) => {    
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/pojisteni/zelenakartaBase64/'+idSmlouva;
  try {
    const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
    return response;
   } catch (error) {
    console.log(error);
    if (error.response) return error.response
    else return {status: -1}; 
   } 
  }   

  export const StahniSoubor = async (accessToken,typ,id) => {    
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/pojisteni/'+typ+'/'+id;
  try {
    //console.log('StahniSoubor');
    const response = await axios.get(REST_API_URL, { responseType:"blob", headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
    //console.log(response);
    return response;
   } catch (error) {
    console.log(error);
    if (error.response) return error.response
    else return {status: -1}; 
   } 
  }