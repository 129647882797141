import axios from 'axios'

export const NahrajDokumenty = async (accessToken, id,typ, fileList) => {
  let param = ''; 
  if (process.env.NODE_ENV === 'development') {
    param = 'files';   
  } else {
    param = 'files[]';   
  }  
    
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      //console.log(fileList[i]);
      formData.append(param, fileList[i]);
    }
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/docs/upload/'+id+'/'+typ;

    try {
        const response = await axios.post(REST_API_URL,formData, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0","Content-Type":"multipart/form-data","Authorization" : `Bearer ${accessToken}`},timeout:(process.env.REACT_APP_UPLOAD_TIMEOUT*fileList.length)});
        // console.log("NahrajDokumenty");
        // console.log(response);        
        return response;  
      } catch(error) {
        console.log(error);
        if (error.response) return error.response
        else return {status: -1};   
     }
}

export const NahrajFotky = async (accessToken, id, fileList) => { 
    //console.log("NahrajFotky");
    let param = ''; 
    if (process.env.NODE_ENV === 'development') {
      param = 'files';   
    } else {
      param = 'files[]';   
    }

    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      //console.log(fileList[i]);
      formData.append(param, fileList[i]);
    }
    const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/foto/upload/'+id;

    try {
        const response = await axios.post(REST_API_URL,formData, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0","Content-Type":"multipart/form-data","Authorization" : `Bearer ${accessToken}`},timeout:(process.env.REACT_APP_UPLOAD_TIMEOUT*fileList.length) });
    // console.log(response);
        return response;  
      } catch(error) {
        console.log(error);
        if (error.response) return error.response
        else return {status: -1};   
     }
}

export const StahniFotky = async (accessToken,id) => {   
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/foto/download/'+id;

  try {
      const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });

      // console.log("StahniFotky");
      // console.log(response);
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1};   
   }
}

export const StahniDokumenty = async (accessToken,id) => {   
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/docs/download/'+id;

  try {
      const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
      //console.log("StahniDokumenty");
      //console.log(response);      
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1};   
   }
}

export const StahniDokument = async (accessToken,id,idSoubor) => {   
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/docs/download/'+id+'/'+idSoubor;

  try {
      const response = await axios.get(REST_API_URL, {responseType:"blob", headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
      //console.log("StahniDokumenty");
      //console.log(response);      
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1};   
   }
}

export const SmazFotku = async (accessToken,id,idFoto) => {   
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/foto/delete/'+id+'/'+idFoto;
  try {
      const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
      // console.log("SmazFotku");
      // console.log(response);      
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1};   
   }
}

export const SmazDokument = async (accessToken,id,idSoubor) => {   
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/docs/delete/'+id+'/'+idSoubor;
  console.log(REST_API_URL);
  try {
      const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
      // console.log("SmazDokument");
      // console.log(response);      
      return response;  
    } catch(error) {
      console.log(error);
      if (error.response) return error.response
      else return {status: -1};   
   }
}