import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField, Accordion, AccordionDetails, AccordionSummary, Box, Typography, Checkbox, Alert, Button, FormControlLabel, Link, Badge } from '@mui/material';
import React, { useState, useEffect, memo } from "react";
import { VnitrniBox } from "./VnitrniBox";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import PojDialog from './PojDialog';
import UniCircularProgressBar from "./UniCircularProgressBar";
import PDF from '../pdf_small.png';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { pink } from '@mui/material/colors';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { loginRequest } from "../authConfig";
import { UpravSmlouvu } from '../services/SmlService';
import { useMsal } from "@azure/msal-react";
import { NedostupnaSluzba, NeocekavanaChyba } from "./StavoveHlasky";
import { VratPojisteni, SjednejPojisteni, StahniSoubor } from '../services/PojService';
import EmailDokumenty from './EmailDokumenty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import fileDownload from 'js-file-download';
import { sendMail } from "../authConfig";
import { OdesliMail } from "../services/MailService";
import { VratZelenouKartuBase64 } from "../services/PojService";
import { isMobile } from 'react-device-detect';

const styles = {
  accorPopis: {
    fontWeight: 'bold',
  },
  stavText: {
    lineHeight: '35px',
    color: 'white',
    backgroundColor: '#8FD14F',
    borderRadius: '8px',
    pl: 1,
    pr: 1,
    fontWeight: 'bold',
  },
  nadpis: {
    width: '100px',
    backgroundColor: '#3C486B',
    color: 'white',
    p: "1px",
    borderRadius: '16px',
    mt: "-28px",
    mb: 1
  },
  nadpisDoc: {
    width: '200px',
    backgroundColor: '#3C486B',
    color: 'white',
    p: "1px",
    borderRadius: '16px',
    mt: "-28px",
    mb: 1
  },
  sekce: {
    flex: "50%",
    p: 2,
    ml: 1,
    mr: 1,
    mt: 1,
    mb: 2,
    borderRadius: '16px',
    borderColor: '#3C486B',
  },
  uniokbadge: {
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#088443',
    }
  },
  uniproblembadge: {
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#a52b71',
    }
  }
};

const Pojisteni = memo(function Pojisteni({ sml, setVIN }) {
  const { instance, accounts } = useMsal();
  let [statusSjednani, setStatusSjednani] = useState(0);
  let [statusDownload, setStatusDownload] = useState(0);
  let [error, setError] = useState("");
  let [loading, setLoading] = useState(true);
  let [progress, setProgress] = useState(false);
  let [kontrolaVIN, setKontrolaVIN] = useState(false);
  let [datumPojisteniOd, setDatumPojisteniOd] = useState(new Date(sml.dpojisteniod));
  let [pojDialogOpen, setPojDialogOpen] = useState(false);
  let [sjednat, setSjednat] = useState(false);
  let [odeslatMailCentrala, setOdeslatMailCentrala] = useState(false);
  let [poj, setPoj] = useState();
  let [email, setEmail] = useState("krahulec@unileasing.cz");
  let [VINpoj, setVINpoj] = useState(sml.karos);
  let [statusAktualizace, setStatusAktualizace] = useState(0);
  let [VINtmp, setVINtmp] = useState(VINpoj);
  let [progressAktualizace, setProgressAktualizace] = useState(false);
  let [zelenaKarta, setZelenaKarta] = useState("");


  async function vratToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    try {
      let respToken = await instance.acquireTokenSilent(request);
      return respToken.accessToken;
    } catch (e) {
      let respToken = await instance.acquireTokenPopup(request);
      return respToken.accessToken;
    }
  }

  async function vratTokenMail() {
    const request = {
      ...sendMail,
      account: accounts[0]
    };

    try {
      let respToken = await instance.acquireTokenSilent(request);
      return respToken.accessToken;
    } catch (e) {
      let respToken = await instance.acquireTokenPopup(request);
      return respToken.accessToken;
    }
  }


  const handleSjednat = () => {
    setPojDialogOpen(true);
  }

  useEffect(() => {
    if (poj) {
      setLoading(false);
    }
  }, [poj]);


  const sjednejPoj = (accessToken) => {
    let dnes = new Date();
    let dSjednani = new Date(datumPojisteniOd);
    dnes.setHours(0, 0, 0, 0);

    if (dSjednani > dnes) {
      dSjednani.setHours(0, 2, 0, 0);
    } else {
      dSjednani = new Date();
    }
    let data = { "dpojisteniod": dSjednani, "pojkod": poj.poj };
    let json = JSON.stringify(data);

    SjednejPojisteni(accessToken, sml.id, json).then(response => {
      if (response.status > 200) setError(response.data);
      if (response.status === 200) {

        setPoj(response.data);
        if (response.data.sjednano) {
          VratZelenouKartuBase64(accessToken, sml.id).then(response => {

            if (response.data) {
              setZelenaKarta(response.data);
              setOdeslatMailCentrala(true);
            }
          });
        }
      }
      setStatusSjednani(response.status);
      setProgress(false);
    });
  }

  const sjednatPojisteni = () => {
    setProgress(true);
    vratToken().then((token) => {
      sjednejPoj(token);
    });
  }

  useEffect(() => {
    if (sjednat) {
      sjednatPojisteni();
    }
    setSjednat(false);
  }, [sjednat]);

  useEffect(() => {
    if (odeslatMailCentrala) {
      odeslatEmailCentrala();
    }
    setOdeslatMailCentrala(false);
  }, [odeslatMailCentrala]);

  const nactiPojisteni = (accessToken) => {
    VratPojisteni(accessToken, sml.id).then(pojTmp => {
      setStatusSjednani(pojTmp.status);
      if (pojTmp.data) {
        // console.log(pojTmp.data);
        setPoj(pojTmp.data);
      }
    });
  }

  useEffect(() => {
    vratToken().then((token) => {
      nactiPojisteni(token);
    });
  }, []);

  const odeslatEmailCentrala = () => {
    let currentDate = new Date();
    let datum = currentDate.getDate() + '.' + (currentDate.getMonth() + 1) + '.' + currentDate.getFullYear() + ' ' + ('0' + currentDate.getHours()).slice(-2) + ':' + ('0' + currentDate.getMinutes()).slice(-2) + ':' + ('0' + currentDate.getSeconds()).slice(-2);
    //let celeJmeno = uziv.jmeno + ' ' + uziv.prijmeni;
    const Zprava = {
      message: {
        subject: 'Nová pojistná smlouva č. ' + poj.cislopojsmlouvy,
        body: {
          contentType: 'HTML',
          content:
            '<b><span style="font-size:15.0pt;color: #003883;">Nová pojistná smlouva č. ' + poj.cislopojsmlouvy + '</span></b><br/>'
            + '<span style="font-size:15.0pt"></span><br/>'
            + 'Datum generování: ' + datum + ' <br/>'
            + 'Pro pobočku: ' + sml.mesto + ' <br/>'
            + 'Obchodník: ' + sml.uzivatel + '<br/><br/>'
            + '<div style="color:#003883">Na smlouvě ' + sml.smlouva + ' byla sjednána nová pojistná smlouva č. ' + poj.cislopojsmlouvy + ' (' + poj.pojistovna + ').<br/>'
            + 'Klient: ' + sml.odb + '<br>'
            + 'Datum počátku pojištění: ' + poj.dpojisteniod + '<br>'
            + 'Předmět: ' + sml.nazev + '<br/><br/><br/>'
            + '<hr size="1" width="100%" noshade="" style="color:#F39800" align="left"/>'
            + '<p style="margin: 0px; padding: 0px;">'
            + '<em>Veškeré informace jsou generované výhradně pro interní účely UNILEASING a.s. a nelze je přeposílat jiným subjektům.<br>Generováno aplikací WEBUNIKALK, uživatel: ' + sml.uzivatel + '</em></p>'
        },
        toRecipients: [
          {
            emailAddress: {
              address: 'krahulec@unileasing.cz'
            }
          }
        ],
        attachments: [
          {
            '@odata.type': '#microsoft.graph.fileAttachment',
            name: 'zelena_karta.pdf',
            contentType: 'application/pdf',
            contentBytes: zelenaKarta
          }
        ]
        // bccRecipients: [
        //     {
        //         emailAddress: {
        //           address: "logger@unileasing.cz"
        //         }
        //     },   
        //     {
        //       emailAddress: {
        //         address: email
        //       }
        //   },                                 
        // ],            
      },
      saveToSentItems: 'true'
    };

    vratTokenMail().then((token) => {
      OdesliMail(token, email, Zprava).then(response => {
        //setStatus(response.status);
      });
    });
  }


  const DoplnujiciUdaje = memo(function DoplnujiciUdaje({ setVIN }) {
    const handleAktualizovat = () => {
      setProgressAktualizace(true);
      aktualizujData();
    }

    const aktualizujData = () => {
      let data = { "karos": VINtmp };

      vratToken().then((token) => {
        UpravSmlouvu(token, sml.id, JSON.stringify(data)).then(response => {
          if (response.status > 200) setError(response.data);
          if (response.status === 200) {
            setVIN(VINtmp);
            setVINpoj(VINtmp);
          }
          setStatusAktualizace(response.status);
          setProgressAktualizace(false);
        });
      });
    }

    const handleChange = (event) => {
      setKontrolaVIN(event.target.checked);
    };

    return (
      <div>
        {poj.zmenaVIN ?
          <VnitrniBox nadpis={"Doplnění VIN"}>
            <div>
              <Box sx={{ display: "flex", width: "100%", paddingTop: 1, gap: { xs: 1 }, alignItems: { xs: "flex-start", md: "center" }, flexDirection: { xs: 'column', md: 'row' } }}>
                <Box sx={{display:"flex"}}>
                  <TextField
                    fullWidth
                    label="VIN"
                    id="kontrola-vin"
                    value={VINtmp}
                    sx={{ width: { xs: "100%", md: "80%" } }}
                    onChange={(event) => {
                      setVINtmp(event.target.value);
                    }}
                    // onInput={(event) => {
                    //   event.target.value = event.target.value.toUpperCase();
                    // }}
                  />
                  {/* {VINtmp.length === 17 ?
                    <CheckCircleIcon sx={{ color: '#dd9234' }} />
                    :
                    <></>
                  } */}
                </Box>
                <Box sx={{ display: "flex", alignItems: 'center' }}>
                  <Button disabled={VINtmp.length === 0} onClick={handleAktualizovat} sx={{ borderColor: '#dd9234', color: '#dd9234' }} component="label" variant="outlined" startIcon={<SystemUpdateAltOutlinedIcon sx={{ color: '#dd9234' }} />}>AKTUALIZOVAT</Button>
                  {progressAktualizace ? <UniCircularProgressBar /> : <></>}
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <div>{statusAktualizace === 200 ? <Alert severity="success">Údaje byly úspěšně zaktualizovány!</Alert> : <p></p>}</div>
                <div>{statusAktualizace === -1 ? <NedostupnaSluzba /> : <p></p>}</div>
                <div>{statusAktualizace > 200 ? <NeocekavanaChyba chyba={error} /> : <p></p>}</div>
              </Box>
            </div>
          </VnitrniBox>
          :
          <VnitrniBox nadpis={"Kontrola VIN"}>
            <div>
              {VINtmp === "" ?
                <Alert severity='warning'>VIN není vyplněno a předmět není ve výrobě. V tuto chvíli nelze sjednat pojištění.</Alert>
                :
                <Box sx={{ display: "flex", width: "100%", paddingTop: 1, gap: { xs: 1 }, flexDirection: { xs: 'column', md: 'row' } }}>
                  <TextField
                    label="VIN"
                    disabled
                    id="kontrola-vin"
                    value={VINtmp}
                    sx={{ width: { xs: "100%", md: "80%" } }}
                  />
                  <Box sx={{ display: "flex", alignItems: 'center' }}>
                    {/* <Button onClick={handleAktualizovat} sx={{ borderColor: '#dd9234', color: '#dd9234', width: { xs: "50%", md: "200px" } }} component="label" variant="outlined" startIcon={<SystemUpdateAltOutlinedIcon sx={{ color: '#dd9234' }} />}>AKTUALIZOVAT</Button>
                  {progress ? <UniCircularProgressBar /> : <></>} */}
                    <FormControlLabel control={<Checkbox checked={kontrolaVIN} onChange={handleChange} sx={{ color: "#dd9234", '&.Mui-checked': { color: "#dd9234", }, }} />} label="Zkontrolováno" />
                  </Box>
                </Box>
              }
            </div>
          </VnitrniBox>
        }
      </div>
    );
  });

  function TabShrnuti() {
    let [openLimit, setOpenLimit] = useState(true);
    let [openSpol, setOpenSpol] = useState(true);
    let [openDopln, setOpenDopln] = useState(true);

    const SLOUPEC = "1px";
    const ODSAZENI = "1px";

    return (
      <Table aria-label="poj-table">
        <TableBody>
          <TableRow key={'poj'}>
            <TableCell width={SLOUPEC} sx={{ p: 0 }}>

            </TableCell>
            <TableCell align="left">Pojišťovna</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.pojistovna}</TableCell>
          </TableRow>
          <TableRow key={'pov'}>
            <TableCell width={SLOUPEC} sx={{ p: 0 }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenLimit(!openLimit)}
              >
                {openLimit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">Povinné ručení</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.zpcelkem !== undefined ? poj.zpcelkem.toLocaleString() : "?"} Kč</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
              <Collapse in={openLimit} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} size="small" aria-label="pov-limit">
                    <TableBody>
                      <TableRow key={"pov-limit"}>
                        <TableCell width={ODSAZENI}></TableCell>
                        <TableCell align='left'>
                          Limit: {poj.povlimit}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow key={'hav'}>
            <TableCell width={SLOUPEC} sx={{ p: 0 }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenSpol(!openSpol)}
              >
                {openSpol ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">Havarijní pojištění</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.hpcelkem !== undefined ? poj.hpcelkem.toLocaleString() : "?"} Kč</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
              <Collapse in={openSpol} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} size="small" aria-label="pov-limit">
                    <TableBody>
                      <TableRow key={"hav-spol"}>
                        <TableCell width={ODSAZENI}></TableCell>
                        <TableCell align='left'>
                          Spoluúčast: {poj.havspol}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow key={'dopln'}>
            <TableCell width={SLOUPEC} sx={{ p: 0 }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenDopln(!openDopln)}
              >
                {openDopln ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">Doplňkové pojištění</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">{poj.doplnkoveCelkem !== undefined ? poj.doplnkoveCelkem.toLocaleString() : "?"} Kč</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
              <Collapse in={openDopln} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} size="small" aria-label="pov-limit">
                    <TableBody>
                      {poj.skloCelkem > 0 ?
                        <TableRow key={"sklo"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            Čelní sklo ({poj.skloText})
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.skloCelkem.toLocaleString()} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      {poj.asistenceText !== "" ?
                        <TableRow key={"asistence"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            {poj.asistenceText}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.asistenceCelkem !== undefined ? poj.asistenceCelkem.toLocaleString() : "?"} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      {poj.nahradniCelkem > 0 ?
                        <TableRow key={"nahradni"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            Náhradní vozidlo ({poj.nahradniText})
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.nahradniCelkem.toLocaleString()} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      {poj.zavazadlaCelkem > 0 ?
                        <TableRow key={"zavazadla"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            Pojištění zavazadel ({poj.zavazadlaText})
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.zavazadlaCelkem.toLocaleString()} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      {poj.urazCelkem > 0 ?
                        <TableRow key={"uraz"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            Pojištění úrazu ({poj.urazText})
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.urazCelkem.toLocaleString()} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      {poj.zverCelkem > 0 ?
                        <TableRow key={"zver"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            Zvěř ({poj.zverText})
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.zverCelkem.toLocaleString()} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                      {poj.gapCelkem > 0 ?
                        <TableRow key={"gap"}>
                          <TableCell width={ODSAZENI}></TableCell>
                          <TableCell >
                            GAP ({poj.gapText})
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{poj.gapCelkem.toLocaleString()} Kč</TableCell>
                        </TableRow>
                        :
                        <></>
                      }
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRow key={'celkem'}>
            <TableCell align="left"></TableCell>
            <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="left">Celkem</TableCell>
            <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="right">{poj.castkaCelkem !== undefined ? poj.castkaCelkem.toLocaleString() : "?"} Kč</TableCell>
          </TableRow>

        </TableBody>
      </Table>
    );
  }

  function DokumentKeStazeni(props) {
    let popisek = "";
    let nazev = "";
    switch (props.dokumentTyp) {
      case "ZELENA_KARTA":
        popisek = "Zelená karta";
        nazev = "zelena_karta.pdf";
        break;
      case "IPID":
        popisek = "Informační dokument o pojistném produktu";
        nazev = "info_pojisteni.pdf";
        break;
      case "IPZ":
        popisek = "Informace o škodní historii";
        nazev = "info_škodní_historie.pdf";
        break;
      case "VPP":
        popisek = "Všeobecné podmínky";
        nazev = "poj_vseobecne_podminky.pdf";
        break;
      case "PI":
        popisek = "Předsmluvní informace";
        nazev = "predsmluvni_informace.pdf";
        break;
    }

    const stahniDokument = () => {
      vratToken().then((token) => {
        StahniSoubor(token, props.dokumentTyp, props.id).then(response => {
          setStatusDownload(response.status);
          if (response.data && response.status === 200) fileDownload(response.data, nazev);
        });
      });
    }

    return (
      <div>
        {
          props.dokumentUrl ?
            <div>
              <Box key={props.dokumentTyp} sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1, ml: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.7, width: "200px" }}>
                  <img src={PDF} alt="pdf" />
                  <Typography>{popisek}</Typography>
                </Box>
                {isMobile ?
                  <div>
                    <IconButton sx={{ p: 0, mr: 1 }} aria-label="download" onClick={() => stahniDokument()}>
                      <FileDownloadOutlinedIcon sx={{ color: '#dd9234' }} />
                    </IconButton>
                  </div>
                  :
                  <Button component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234' }} onClick={() => stahniDokument()}>Stáhnout</Button>
                }
              </Box>
              <div>{statusDownload === -1 ? <NedostupnaSluzba /> : <></>}</div>
              <div>{statusDownload > 200 ? <NeocekavanaChyba chyba={"Soubor se nepodařilo stáhnout. Kontaktujte IT oddělení!"} /> : <></>}</div>
            </div>
            :
            <></>
        }
      </div>
    );
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Typography sx={styles.accorPopis}>Pojištění</Typography>
        {loading ?
          <></>
          :
          <div>
            {poj.sjednano ?
              <DescriptionOutlinedIcon sx={{ mr: 1, p: '2px', color: 'white', bgcolor: '#8FD14F', fontSize: '35px', borderRadius: '8px' }} />
              :
              <DescriptionOutlinedIcon sx={{ mr: 1, p: '2px', color: 'white', bgcolor: pink[500], fontSize: '35px', borderRadius: '8px' }} />
            }
          </div>
        }

      </AccordionSummary>
      <AccordionDetails>
        {loading ?
          <></>
          :
          <div>
            {poj.sjednano ?
              <></>
              :
              <DoplnujiciUdaje setVIN={setVIN} />
            }
            <VnitrniBox nadpis={"Shrnutí"}>
              <TableContainer component={Box} sx={{ width: { xs: "100%", md: "80%" }, margin: "auto" }}>
                <TabShrnuti />

                {poj.sjednano ?
                  <></>
                  :
                  <div>
                    {!poj.zmenaVIN && !kontrolaVIN ?
                      <Box sx={{ mt: 2, display: "flex", gap: 1, alignItems: { xs: "flex-start", md: "center" }, flexDirection: { xs: "column", md: "row" } }}>
                        <Button disabled component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234' }}>SJEDNAT</Button>
                        {progress ? <UniCircularProgressBar /> : <></>}
                        {VINpoj === "" ?
                          <></> :

                          <Alert severity="info">Před sjednáním pojištění proveďte kontrolu VIN</Alert>
                        }
                      </Box>
                      :
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {VINpoj === "" ?
                          <Button disabled component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ mt: 2, mb: 2, borderColor: '#dd9234', color: '#dd9234' }}>SJEDNAT</Button>
                          :
                          <Button onClick={handleSjednat} component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ mt: 2, mb: 2, borderColor: '#dd9234', color: '#dd9234' }}>SJEDNAT</Button>
                        }
                        {progress ? <UniCircularProgressBar /> : <></>}
                      </Box>
                    }
                  </div>
                }

                <PojDialog datumPojisteniOd={datumPojisteniOd} setDatumPojisteniOd={setDatumPojisteniOd} pojDialogOpen={pojDialogOpen} setPojDialogOpen={setPojDialogOpen} setSjednat={setSjednat}></PojDialog>
                {poj.sjednano ? <Alert sx={{ mt: 2 }} severity="success">Pojištění sjednáno k {poj.dpojisteniod}</Alert> : <></>}
                <div>{statusSjednani === -1 ? <NedostupnaSluzba /> : <p></p>}</div>
                <div>{statusSjednani > 200 ? <NeocekavanaChyba chyba={error} /> : <p></p>}</div>
                {poj.sjednano ?
                  <Box sx={{ mt: 2 }}>
                    {poj.state === 'ERR_DOCS' ?
                      <Alert severity="error">{poj.errors}</Alert>
                      :
                      <div>
                        <Typography sx={{ mb: 1 }}><b>Dokumenty ke stažení</b></Typography>

                        <DokumentKeStazeni dokumentUrl={poj.prints.zelenaKarta} dokumentTyp={"ZELENA_KARTA"} id={sml.id} />
                        <DokumentKeStazeni dokumentUrl={poj.prints.ipz} dokumentTyp={"IPZ"} id={sml.id} />
                        <DokumentKeStazeni dokumentUrl={poj.prints.vpp} dokumentTyp={"VPP"} id={sml.id} />
                        <DokumentKeStazeni dokumentUrl={poj.prints.ipid} dokumentTyp={"IPID"} id={sml.id} />
                        <DokumentKeStazeni dokumentUrl={poj.prints.pi} dokumentTyp={"PI"} id={sml.id} />

                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "flex-start", md: "center" }, gap: 2, mt: 4 }}>
                          <TextField
                            label={"Email"}
                            id="outlined-controlled"
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                          />
                          <EmailDokumenty poj={poj} email={email} sml={sml} VIN={VINpoj} />
                          {/* <Button component="label" variant="outlined" startIcon={<EmailOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }}>Odeslat dokumenty na email</Button>                 */}
                        </Box>
                      </div>
                    }
                  </Box>
                  :
                  <></>}
              </TableContainer>

            </VnitrniBox>
          </div>
        }
      </AccordionDetails>
    </Accordion>
  );
});

export default Pojisteni;


